import * as R from 'ramda';
import React from 'react';
import PropositionInfoDisplayProp from './components/PropositionInfoDisplayProp';
import PropositionInfoDisplayHeader from './components/PropositionInfoDisplayHeader';
import {
  formatDate,
  formatStartDate,
  getCollegesInformations,
  yesNo,
  getRole,
  getStatus
} from './helpers/propositionInfoDisplay';

const PropositionInfoDisplay = ({
  amendment,
  proposition,
  style = {}
}) => {

  const { startDate, status, medicalCheck, outstandingCheck } = proposition;

  const contractNumbers = R.compose(
    R.join(', '),
    R.flatten,
    R.values,
    R.pick(['contractNumbers', 'contractNumbersHealth']),
    R.modify('contractNumbers', R.defaultTo([])),
    R.assoc('contractNumbersHealth', R.pathOr([], ['health', 'contractNumbers'])(proposition))
  )(proposition);

  const get = (path, or = '') => R.pathOr(or, path)(proposition);
  const getAddress = () => `${R.pathOr([], ['siretInfo', 'address', 'fields'])(proposition).join(' ')} - ${get(['siretInfo', 'address', 'postCode'])} ${get(['siretInfo', 'address', 'city'])}`;
  const contactMobile = get(['companyInformation', 'contactMobile']);
  const colleges = getCollegesInformations(proposition);
  const role = get(['user', 'role']);

  return <div style={style}>
    <PropositionInfoDisplayHeader title={'Informations générales'} hidden={amendment}>
      <PropositionInfoDisplayProp label={'Numéros de contrat'} value={contractNumbers} placeholder={'Aucun numéro de contrat renseigné.'}/>
      <PropositionInfoDisplayProp label={'Création'} value={formatDate(get(['dates', 'creation']))} placeholder={'Date de création inconnue.'}/>
      <PropositionInfoDisplayProp label={'Modification'} value={formatDate(get(['dates', 'lastUpdate']))} placeholder={'Date de modification inconnue.'}/>
      <PropositionInfoDisplayProp label={'Statut'} value={getStatus(status)} placeholder={'Statut inconnu.'}/>
      <PropositionInfoDisplayProp label={'Date d\'effet'} value={formatStartDate(startDate)} placeholder={'Date d\'effet inconnue.'}/>
      <PropositionInfoDisplayProp label={'Convention Collective'} value={`${get(['ccn', 'id'])} - ${get(['ccn', 'shortTitle'])}`} placeholder={'CCN non trouvée.'}/>
    </PropositionInfoDisplayHeader>

    <PropositionInfoDisplayHeader title={'Émetteur'} hidden={true}>
      <PropositionInfoDisplayProp label={'Émetteur'} value={`${get(['user', 'extra', 'firstname'])} ${get(['user', 'extra', 'lastname'])}`}/>
      <PropositionInfoDisplayProp label={'Rôle'} value={getRole(role)}/>
      <PropositionInfoDisplayProp label={'Région'} value={get(['regionTitle'])}/>
      { role === 'groupe_adhesion' && <PropositionInfoDisplayProp label={'Centre de gestion'} value={get(['managementCenterName'])}/> }
    </PropositionInfoDisplayHeader>

    <PropositionInfoDisplayHeader title={'Entreprise'} hidden={true}>
      <PropositionInfoDisplayProp label={'Raison sociale'} value={get(['siretInfo', 'name'])}/>
      <PropositionInfoDisplayProp label={'Adresse'} value={getAddress()}/>
      <PropositionInfoDisplayProp label={'Siren'} value={get(['siren'])}/>
      <PropositionInfoDisplayProp label={'Siret'} value={get(['siret'])}/>
    </PropositionInfoDisplayHeader>

    {get(['relatedContract', 'checked']) && (
      <PropositionInfoDisplayHeader title={'Suite économique'} hidden={true}>
        <PropositionInfoDisplayProp label={'Ancienne dénomination sociale'} value={get(['relatedContract', 'oldLegalForm'])}/>
        <PropositionInfoDisplayProp label={'Numéros de contrat'} value={R.join(', ', get(['relatedContract', 'contractNumbers'], []))}/>
      </PropositionInfoDisplayHeader>
    )}

    <PropositionInfoDisplayHeader title={'Contact de l\'entreprise'} hidden={true}>
      <PropositionInfoDisplayProp label={'Contact'} value={`${get(['companyInformation', 'contactFirstname'])} ${get(['companyInformation', 'contactLastname'])}`} placeholder={'Informations non renseignées.'}/>
      <PropositionInfoDisplayProp label={'Adresse mail'} value={get(['companyInformation', 'contactEmail'])}/>
      { !R.isEmpty(contactMobile) && <PropositionInfoDisplayProp label={'Numéro de téléphone'} value={contactMobile}/> }
      <PropositionInfoDisplayProp label={'Rôle'} value={get(['companyInformation', 'contactRole'])} placeholder={'Rôle non renseigné.'}/>
    </PropositionInfoDisplayHeader>

    <PropositionInfoDisplayHeader title={'Description du contrat'} hidden={amendment}>
      <PropositionInfoDisplayProp label={'Collèges sélectionnés'} value={colleges.string} placeholder={'Aucun collège sélectionné.'}/>
      { colleges.NC_isSelected && <PropositionInfoDisplayProp label={'Contrats Non Cadre'} value={get(['basesAndOptionsNamesNC'])}/> }
      { colleges.AM_isSelected && <PropositionInfoDisplayProp label={'Contrats ETAM'} value={get(['basesAndOptionsNamesAM'])}/> }
      { colleges.C_isSelected && <PropositionInfoDisplayProp label={'Contrats Cadre'} value={get(['basesAndOptionsNamesC'])}/> }
      { colleges.TOUS_isSelected && <PropositionInfoDisplayProp label={'Contrats Ensemble du personnel'} value={get(['basesAndOptionsNamesTOUS'])}/> }
      <PropositionInfoDisplayProp label={'Questionnaire médical'} value={yesNo(medicalCheck)}/>
      <PropositionInfoDisplayProp label={'Majoration'} value={yesNo(!R.isEmpty(get(['overPricedRate'])))}/>
      <PropositionInfoDisplayProp label={'Reprise d\'encours'} value={yesNo(outstandingCheck)}/>
    </PropositionInfoDisplayHeader>
  </div>;
};

export default PropositionInfoDisplay;
