/**
 * @flow
 * @relayHash 89e7485d3edde6bb1e8cde0055bac0c7
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type OfferTypeEnum = "PREVOYANCE" | "SANTE" | "%future added value";
export type StaffFiguresInput = {|
  AM?: ?StaffFigureInput,
  APP?: ?StaffFigureInput,
  C?: ?StaffFigureInput,
  NC?: ?StaffFigureInput,
  TOUS?: ?StaffFigureInput,
|};
export type StaffFigureInput = {|
  count?: ?number,
  isSelected?: ?boolean,
|};
export type BrokerageInput = {|
  commercialCode?: ?string,
  commercialCodeUserEmail?: ?string,
  isSelected?: ?boolean,
  provider?: ?string,
|};
export type ContractDescriptionInput = {|
  AM?: ?ProductInput,
  APP?: ?ProductInput,
  C?: ?ProductInput,
  NC?: ?ProductInput,
  NC_C?: ?ProductInput,
  TOUS?: ?ProductInput,
|};
export type ProductInput = {|
  bases?: ?$ReadOnlyArray<?BaseProductInput>
|};
export type BaseProductInput = {|
  baseId?: ?string,
  options?: ?$ReadOnlyArray<?BaseProductOptionInput>,
|};
export type BaseProductOptionInput = {|
  optionId?: ?string
|};
export type AmendmentInput = {|
  formerColleges?: ?$ReadOnlyArray<?string>,
  formerContractDescription?: ?ContractDescriptionInput,
  formerContractId?: ?string,
  formerNumberOfContracts?: ?number,
  isAmendment?: ?boolean,
|};
export type IsMedicalCheckRequiredMutationVariables = {|
  propositionId: string,
  ccnId: string,
  offerType: OfferTypeEnum,
  ccnVersion?: ?number,
  staff?: ?StaffFiguresInput,
  brokerage?: ?BrokerageInput,
  contractDescription?: ?ContractDescriptionInput,
  postCode?: ?string,
  amendment?: ?AmendmentInput,
  duplicata: boolean,
  tariffStructure?: ?string,
|};
export type IsMedicalCheckRequiredMutationResponse = {|
  +isMedicalCheckRequired: ?{|
    +ok: boolean,
    +error: ?string,
    +medicalCheck: ?boolean,
  |}
|};
export type IsMedicalCheckRequiredMutation = {|
  variables: IsMedicalCheckRequiredMutationVariables,
  response: IsMedicalCheckRequiredMutationResponse,
|};
*/


/*
mutation IsMedicalCheckRequiredMutation(
  $propositionId: String!
  $ccnId: String!
  $offerType: OfferTypeEnum!
  $ccnVersion: Int
  $staff: StaffFiguresInput
  $brokerage: BrokerageInput
  $contractDescription: ContractDescriptionInput
  $postCode: String
  $amendment: AmendmentInput
  $duplicata: Boolean!
  $tariffStructure: String
) {
  isMedicalCheckRequired(propositionId: $propositionId, ccnId: $ccnId, offerType: $offerType, ccnVersion: $ccnVersion, staff: $staff, brokerage: $brokerage, contractDescription: $contractDescription, postCode: $postCode, amendment: $amendment, duplicata: $duplicata, tariffStructure: $tariffStructure) {
    ok
    error
    medicalCheck
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "propositionId",
    "type": "String!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ccnId",
    "type": "String!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "offerType",
    "type": "OfferTypeEnum!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ccnVersion",
    "type": "Int"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "staff",
    "type": "StaffFiguresInput"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "brokerage",
    "type": "BrokerageInput"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "contractDescription",
    "type": "ContractDescriptionInput"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "postCode",
    "type": "String"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "amendment",
    "type": "AmendmentInput"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "duplicata",
    "type": "Boolean!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "tariffStructure",
    "type": "String"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "amendment",
        "variableName": "amendment"
      },
      {
        "kind": "Variable",
        "name": "brokerage",
        "variableName": "brokerage"
      },
      {
        "kind": "Variable",
        "name": "ccnId",
        "variableName": "ccnId"
      },
      {
        "kind": "Variable",
        "name": "ccnVersion",
        "variableName": "ccnVersion"
      },
      {
        "kind": "Variable",
        "name": "contractDescription",
        "variableName": "contractDescription"
      },
      {
        "kind": "Variable",
        "name": "duplicata",
        "variableName": "duplicata"
      },
      {
        "kind": "Variable",
        "name": "offerType",
        "variableName": "offerType"
      },
      {
        "kind": "Variable",
        "name": "postCode",
        "variableName": "postCode"
      },
      {
        "kind": "Variable",
        "name": "propositionId",
        "variableName": "propositionId"
      },
      {
        "kind": "Variable",
        "name": "staff",
        "variableName": "staff"
      },
      {
        "kind": "Variable",
        "name": "tariffStructure",
        "variableName": "tariffStructure"
      }
    ],
    "concreteType": "IsMedicalCheckRequiredMutationResponse",
    "kind": "LinkedField",
    "name": "isMedicalCheckRequired",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "medicalCheck",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "IsMedicalCheckRequiredMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "IsMedicalCheckRequiredMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "89e7485d3edde6bb1e8cde0055bac0c7",
    "metadata": {},
    "name": "IsMedicalCheckRequiredMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9198fc8c580c82f868cbc40bd79ca609';

module.exports = node;
