import React from 'react';
import { BtnNext } from './index';
import { FieldTitle } from '../../../uikit/UIKit';
import useKeyPressEnter from '../../../hooks/useKeyPressEnter';
import { hooks, libs, redux } from '@fasstech/spid-front';
import { map, isEmpty, length, pathEq, propOr, path, isNil, filter, propEq, applySpec, compose, clamp } from 'ramda';
import VisionService from './contractDescription/VisionService';
import { connect } from 'react-redux';

const LinkButton = ({
  href,
  isSelected,
  label
}) => {
  if (isNil(href)) {
    return null;
  }

  return <a
    className={`font-bold cursor-pointer underline ${isSelected ? 'text-ag2r-brown' : 'text-ag2r-blue hover:text-ag2r-brown'}`}
    href={href}
    target="_blank" rel="noreferrer"
  >
    {label}
  </a>;
};

const ContractDescriptionSante = ({
  amendment,
  bases,
  ccn,
  colleges,
  duplicata,
  goPreviousStep,
  goPreviousStepAmendment,
  hasValue,
  isUnknownAmendment,
  onNext,
  onUpdate,
  onUpdateAmendment,
  startDate,
  stepAmendment,
  value,
  visionServiceUrl,
  mustHaveAtLeastOneOfIsValid,
  selectedContractDescription,
  setContractDescription,
  onSelectBase,
  updateBases,
  loading
}) => {
  const {
    isClassic,
    messages,
    buttonDisabled,
    buttonOnNext,
    displayIsOriginEquals,
    displayCollegesDontHaveBase
  } = hooks.useContractDescription({
    bases,
    value,
    startDate,
    duplicata,
    amendment,
    isUnknownAmendment,
    stepAmendment,
    hasValue,
    colleges,
    onNext,
    onUpdateAmendment,
    ccn,
    mustHaveAtLeastOneOfIsValid
  });

  useKeyPressEnter(buttonOnNext, !buttonDisabled);

  React.useEffect(() => {
    setContractDescription(value);
    updateBases(bases, colleges, {});
  }, []);

  React.useEffect(() => {
    onUpdate(selectedContractDescription);
  }, [selectedContractDescription]);

  const {
    garantiesRdgUrl,
    cotisationsRdgUrl
  } = applySpec({
    garantiesRdgUrl: path(['SANTE', 'garantiesRdgUrl']),
    cotisationsRdgUrl: path(['SANTE', 'cotisationsRdgUrl'])
  })(ccn);

  return <div>
    <FieldTitle
      text={!stepAmendment ? 'Les contrats possibles par collège' : 'Sélection du contrat d\'origine'}
      onBack={!isUnknownAmendment || stepAmendment ? goPreviousStep : goPreviousStepAmendment}
    />

    <div className="contract-description-actions">
      <VisionService visionServiceUrl={visionServiceUrl}/>
    </div>

    <div className="contract-description-step-colleges">
      {map(college => {
        const _bases = filter(propEq('college', college))(bases);
        const numberOfColumns = compose(
          clamp(0, 4),
          length
        )(_bases);

        return <div
          className="contracts"
          style={{ maxWidth: '100%' }}
          key={`sante-${college}`}
        >
          <div className="contracts-title">
            {libs.collegeLabel(college, ccn, 'SANTE')}
          </div>

          <div
            className="grid bg-gray-50"
            style={{ gridTemplateColumns: `repeat(${numberOfColumns}, minmax(0, 1fr))` }}
          >
            {map(base => {
              const isSelected = pathEq([college, base.id, 'selected'], true)(selectedContractDescription);

              return <div
                className={`flex flex-col gap-y-8 pt-2 pb-6 px-1 items-center justify-between text-center transition ${isSelected ? 'bg-[#b2ebff] text-white' : ''}`}
                key={`sante-${college}-${base.id}`}
              >
                <div className={`text-[18px] font-bold shadow w-full py-2 rounded-xl ${isSelected ? 'bg-ag2r-brown' : 'bg-white'}`}>{base.name}</div>

                <div className="flex flex-col gap-y-8">
                  <div className="flex flex-col">
                    <LinkButton
                      href={garantiesRdgUrl}
                      isSelected={isSelected}
                      label="Garanties"
                    />

                    <LinkButton
                      href={cotisationsRdgUrl}
                      isSelected={isSelected}
                      label="Cotisations"
                    />
                  </div>

                  {!isSelected && <button
                    className="text-[12px] bg-ag2r-blue hover:bg-ag2r-brown rounded-2xl text-white font-semibold border-none px-6 py-3 cursor-pointer"
                    onClick={() => onSelectBase(college, base.id, true)}
                  >
                    Choisir
                  </button>}

                  {isSelected && <div className="h-[42px]"/>}
                </div>
              </div>;
            })(_bases)}
          </div>
        </div>;
      })(colleges)}
    </div>

    <div>
      {displayIsOriginEquals && <div style={{ color: 'red' }}>L’avenant est identique au contrat d’origine, la souscription de l’avenant est impossible.</div>}
      {displayCollegesDontHaveBase && <div style={{ color: 'red' }}>L’avenant est impossible.</div>}
      {isClassic && !stepAmendment && <div style={{ color: 'red' }}>Vous ne pouvez pas créer d’avenant, il est nécessaire de créer une nouvelle souscription.</div>}
      {!isEmpty(messages) && <div style={{ color: 'red' }}>
        {map(message => <span key={message}>{message}<br/></span>)(messages)}
      </div>}

      <BtnNext
        disabled={buttonDisabled}
        onClick={buttonOnNext}
        loading={loading}
        title={stepAmendment ? 'Valider le contrat d\'origine' : 'Valider'}
      />
    </div>
  </div>;
};

export default connect(
  state => {
    return {
      selectedContractDescription: state.contractDescription.value
    };
  },
  (dispatch) => ({
    setContractDescription: (value) => dispatch(redux.actions.contractDescription.contractDescriptionSetValue(value)),
    updateBases: (bases, colleges, amendment) => dispatch(redux.actions.contractDescription.contractDescriptionSetBases(bases, colleges, propOr(false, 'isAmendment', amendment))),
    onSelectBase: (college, baseId, isSelected) => dispatch(redux.actions.contractDescription.contractDescriptionSelectBase(college, baseId, isSelected))
  })
)(ContractDescriptionSante);
