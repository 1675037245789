/**
 * @flow
 * @relayHash 0c2dc0909c3c3e909b26d3e077183af5
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PropositionStatusEnum = "active" | "closed" | "confirmed" | "confirmedInManagementSystem" | "confirmedInManagementSystemReopened" | "done" | "duplicate" | "ready" | "rejected" | "rejectedByManagementSystem" | "sendingForSignature" | "sentForSignature" | "sentForSignatureDelayed" | "sentToManagementSystem" | "unhold" | "%future added value";
export type UserRoleEnum = "admin" | "apporteur" | "groupe_adhesion" | "manager" | "reseau_commercial" | "%future added value";
export type AmendmentQueryVariables = {|
  contractNumber?: ?string,
  status?: ?string,
|};
export type AmendmentQueryResponse = {|
  +propositionInfo: ?{|
    +id: ?string,
    +ccn: ?{|
      +id: ?string,
      +shortTitle: ?string,
      +disableAmendments: ?$ReadOnlyArray<?number>,
      +isPack: ?boolean,
    |},
    +ccnVersion: ?number,
    +postCode: ?string,
    +contractNumbers: ?$ReadOnlyArray<?string>,
    +background: ?{|
      +hasFormerContract: ?boolean,
      +atmtt: ?{|
        +isSelected: ?boolean,
        +count: ?number,
      |},
    |},
    +brokerage: ?{|
      +isSelected: ?boolean
    |},
    +startDate: ?string,
    +dates: ?{|
      +creation: ?string,
      +lastUpdate: ?string,
    |},
    +propositionNumber: ?number,
    +siren: ?string,
    +siret: ?string,
    +siretInfo: ?{|
      +siret: ?string,
      +siren: ?string,
      +name: ?string,
      +legalForm: ?string,
      +address: ?{|
        +city: ?string,
        +postCode: ?string,
        +fields: ?$ReadOnlyArray<?string>,
      |},
    |},
    +status: ?PropositionStatusEnum,
    +medicalCheck: ?boolean,
    +declarativeAnnexe: ?string,
    +outstandingAnnexe: ?string,
    +outstandingCheck: ?boolean,
    +companyInformation: ?{|
      +contactFirstname: ?string,
      +contactLastname: ?string,
      +contactEmail: ?string,
      +contactMobile: ?string,
      +contactRole: ?string,
    |},
    +staff: ?{|
      +NC: ?{|
        +isSelected: ?boolean,
        +count: ?number,
      |},
      +AM: ?{|
        +isSelected: ?boolean,
        +count: ?number,
      |},
      +C: ?{|
        +isSelected: ?boolean,
        +count: ?number,
      |},
    |},
    +contractDescription: ?{|
      +NC: ?{|
        +bases: ?$ReadOnlyArray<?{|
          +baseId: ?string,
          +type: ?string,
          +options: ?$ReadOnlyArray<?{|
            +optionId: ?string
          |}>,
        |}>
      |},
      +AM: ?{|
        +bases: ?$ReadOnlyArray<?{|
          +baseId: ?string,
          +type: ?string,
          +options: ?$ReadOnlyArray<?{|
            +optionId: ?string
          |}>,
        |}>
      |},
      +C: ?{|
        +bases: ?$ReadOnlyArray<?{|
          +baseId: ?string,
          +type: ?string,
          +options: ?$ReadOnlyArray<?{|
            +optionId: ?string
          |}>,
        |}>
      |},
    |},
    +duplicata: ?boolean,
    +relatedContract: ?{|
      +checked: ?boolean,
      +oldLegalForm: ?string,
      +contractNumbers: ?$ReadOnlyArray<?string>,
    |},
    +user: ?{|
      +email: ?string,
      +role: ?UserRoleEnum,
      +extra: ?{|
        +userCode: ?string,
        +region: ?string,
        +managementCenter: ?string,
        +lastname: ?string,
        +firstname: ?string,
        +sales: ?string,
      |},
    |},
    +overPricedRate: ?number,
    +regionTitle: ?string,
    +managementCenterName: ?string,
    +basesAndOptionsNamesC: ?string,
    +basesAndOptionsNamesNC: ?string,
    +basesAndOptionsNamesAM: ?string,
  |}
|};
export type AmendmentQuery = {|
  variables: AmendmentQueryVariables,
  response: AmendmentQueryResponse,
|};
*/


/*
query AmendmentQuery(
  $contractNumber: String
  $status: String
) {
  propositionInfo(contractNumber: $contractNumber, status: $status) {
    id
    ccn {
      id
      shortTitle
      disableAmendments
      isPack
    }
    ccnVersion
    postCode
    contractNumbers
    background {
      hasFormerContract
      atmtt {
        isSelected
        count
      }
    }
    brokerage {
      isSelected
    }
    startDate
    dates {
      creation
      lastUpdate
    }
    propositionNumber
    siren
    siret
    siretInfo {
      siret
      siren
      name
      legalForm
      address {
        city
        postCode
        fields
      }
    }
    status
    medicalCheck
    declarativeAnnexe
    outstandingAnnexe
    outstandingCheck
    companyInformation {
      contactFirstname
      contactLastname
      contactEmail
      contactMobile
      contactRole
    }
    staff {
      NC {
        isSelected
        count
      }
      AM {
        isSelected
        count
      }
      C {
        isSelected
        count
      }
    }
    contractDescription {
      NC {
        bases {
          baseId
          type
          options {
            optionId
          }
        }
      }
      AM {
        bases {
          baseId
          type
          options {
            optionId
          }
        }
      }
      C {
        bases {
          baseId
          type
          options {
            optionId
          }
        }
      }
    }
    duplicata
    relatedContract {
      checked
      oldLegalForm
      contractNumbers
    }
    user {
      email
      role
      extra {
        userCode
        region
        managementCenter
        lastname
        firstname
        sales
      }
      id
    }
    overPricedRate
    regionTitle
    managementCenterName
    basesAndOptionsNamesC
    basesAndOptionsNamesNC
    basesAndOptionsNamesAM
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "contractNumber",
    "type": "String"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "status",
    "type": "String"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "contractNumber",
    "variableName": "contractNumber"
  },
  {
    "kind": "Variable",
    "name": "status",
    "variableName": "status"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "Ccn",
  "kind": "LinkedField",
  "name": "ccn",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shortTitle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "disableAmendments",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isPack",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ccnVersion",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "postCode",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "contractNumbers",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isSelected",
  "storageKey": null
},
v8 = [
  (v7/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "count",
    "storageKey": null
  }
],
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "Background",
  "kind": "LinkedField",
  "name": "background",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasFormerContract",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BackgroundAtmtt",
      "kind": "LinkedField",
      "name": "atmtt",
      "plural": false,
      "selections": (v8/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "Brokerage",
  "kind": "LinkedField",
  "name": "brokerage",
  "plural": false,
  "selections": [
    (v7/*: any*/)
  ],
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startDate",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "Dates",
  "kind": "LinkedField",
  "name": "dates",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "creation",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastUpdate",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "propositionNumber",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "siren",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "siret",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "concreteType": "SiretInfo",
  "kind": "LinkedField",
  "name": "siretInfo",
  "plural": false,
  "selections": [
    (v15/*: any*/),
    (v14/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "legalForm",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Address",
      "kind": "LinkedField",
      "name": "address",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "city",
          "storageKey": null
        },
        (v5/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fields",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "medicalCheck",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "declarativeAnnexe",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "outstandingAnnexe",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "outstandingCheck",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "concreteType": "CompanyInformation",
  "kind": "LinkedField",
  "name": "companyInformation",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contactFirstname",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contactLastname",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contactEmail",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contactMobile",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contactRole",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "concreteType": "StaffFigures",
  "kind": "LinkedField",
  "name": "staff",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "StaffFigure",
      "kind": "LinkedField",
      "name": "NC",
      "plural": false,
      "selections": (v8/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "StaffFigure",
      "kind": "LinkedField",
      "name": "AM",
      "plural": false,
      "selections": (v8/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "StaffFigure",
      "kind": "LinkedField",
      "name": "C",
      "plural": false,
      "selections": (v8/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
},
v24 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "BaseProduct",
    "kind": "LinkedField",
    "name": "bases",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "baseId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "type",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "BaseOptionProduct",
        "kind": "LinkedField",
        "name": "options",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "optionId",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v25 = {
  "alias": null,
  "args": null,
  "concreteType": "ContractDescription",
  "kind": "LinkedField",
  "name": "contractDescription",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Product",
      "kind": "LinkedField",
      "name": "NC",
      "plural": false,
      "selections": (v24/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Product",
      "kind": "LinkedField",
      "name": "AM",
      "plural": false,
      "selections": (v24/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Product",
      "kind": "LinkedField",
      "name": "C",
      "plural": false,
      "selections": (v24/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "duplicata",
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "concreteType": "RelatedContract",
  "kind": "LinkedField",
  "name": "relatedContract",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "checked",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "oldLegalForm",
      "storageKey": null
    },
    (v6/*: any*/)
  ],
  "storageKey": null
},
v28 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v29 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "role",
  "storageKey": null
},
v30 = {
  "alias": null,
  "args": null,
  "concreteType": "AccountExtra",
  "kind": "LinkedField",
  "name": "extra",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userCode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "region",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "managementCenter",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastname",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstname",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sales",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v31 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "overPricedRate",
  "storageKey": null
},
v32 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "regionTitle",
  "storageKey": null
},
v33 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "managementCenterName",
  "storageKey": null
},
v34 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "basesAndOptionsNamesC",
  "storageKey": null
},
v35 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "basesAndOptionsNamesNC",
  "storageKey": null
},
v36 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "basesAndOptionsNamesAM",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AmendmentQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Proposition",
        "kind": "LinkedField",
        "name": "propositionInfo",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/),
          (v16/*: any*/),
          (v17/*: any*/),
          (v18/*: any*/),
          (v19/*: any*/),
          (v20/*: any*/),
          (v21/*: any*/),
          (v22/*: any*/),
          (v23/*: any*/),
          (v25/*: any*/),
          (v26/*: any*/),
          (v27/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Account",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v28/*: any*/),
              (v29/*: any*/),
              (v30/*: any*/)
            ],
            "storageKey": null
          },
          (v31/*: any*/),
          (v32/*: any*/),
          (v33/*: any*/),
          (v34/*: any*/),
          (v35/*: any*/),
          (v36/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AmendmentQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Proposition",
        "kind": "LinkedField",
        "name": "propositionInfo",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/),
          (v16/*: any*/),
          (v17/*: any*/),
          (v18/*: any*/),
          (v19/*: any*/),
          (v20/*: any*/),
          (v21/*: any*/),
          (v22/*: any*/),
          (v23/*: any*/),
          (v25/*: any*/),
          (v26/*: any*/),
          (v27/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Account",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v28/*: any*/),
              (v29/*: any*/),
              (v30/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v31/*: any*/),
          (v32/*: any*/),
          (v33/*: any*/),
          (v34/*: any*/),
          (v35/*: any*/),
          (v36/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "0c2dc0909c3c3e909b26d3e077183af5",
    "metadata": {},
    "name": "AmendmentQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4451a30e291237fa4ac8a0ab54a9d28a';

module.exports = node;
