import * as R from 'ramda';

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { graphql, QueryRenderer } from 'react-relay';
import environment from './graphql/Environment';

import Moment from 'moment';
import Error from './components/Error';

Moment.locale('fr');

const UserQuery = graphql`
query UserQuery {
  user {
    id
    username
    email
    role
    extra {
      userCode
      region
      firstname
      lastname
      managementCenter
      isManager
      sales
      providers
      signUpNeeded
    }
    grants
    resetPasswordRequired
    status
  }
  config {
    arpejLink
  }
} 
`;

const useUser = (value) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    if (!R.isNil(value)) setUser(value);
  }, [value]);

  return user;
};

const User = ({
  value,
  render
}) => {
  const user = useUser(value);
  if (R.isNil(user)) return null;
  return render(user);
};

const useUserRefresh = (interval) => {
  const [userRefreshKey, setUserRefreshKey] = useState(0);
  const ref = useRef();
  useEffect(() => {
    ref.current = setInterval(
      () => {
        setUserRefreshKey(userRefreshKey + 1);
      },
      interval
    );
    return (() => clearInterval(ref.current));
  });

  return userRefreshKey;
};

const UserWrapper = ({
  refreshInterval,
  children
}) => (
  /**
   * on déclare une variable key qui à chaque fois que sa valeur change va générer ue nouvelle requête.
   * on préfère cette solution à l'utilisation d'une prop key car si la clef change les enfants sont détruits.
   */
  <QueryRenderer
    environment={environment}
    query={UserQuery}
    variables={{
      key: useUserRefresh(refreshInterval)
    }}
    render={({ error, props }) => {
      let user;
      if (error) {
        return <Error/>;
      } else if (!props) {
        user = null;
      } else {
        user = props.user;
      }

      return (
        <User
          value={user}
          render={children}
        />
      );
    }}
  />
);

UserWrapper.propTypes = {
  refreshInterval: PropTypes.number
};

UserWrapper.defaultProps = {
  refreshInterval: 60000
};

export default UserWrapper;
