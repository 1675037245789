import './DocumentsStep.less';

import * as R from 'ramda';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import S from 'string';

import {
  propositionDocumentsGet,
  propositionGoNextStep,
  propositionGoPreviousStep,
  propositionFetchingReset,
  propositionPutUnhold, propositionGenerateContractNumbers
} from '../../../reduxActions/proposition';

import { FieldTitle, Button } from '../../../uikit/UIKit';
import { BtnNext, Documents } from '../components/.';
import useKeyPressEnter from '../../../hooks/useKeyPressEnter';
import withUser from '../../../withUser';
import withConfig from '../../../withConfig';
import { libs } from '@fasstech/spid-front';
import Loading from '../../../components/loading';

const useDocuments = ({
  getDocuments,
  goPreviousStep,
  resetFetching,
  goNextStep,
  putUnhold,
  medicalCheck,
  duplicata,
  propositionStatusIsUnhold,
  fetching,
  user,
  generateContractNumbers,
  config,
  amendment,
  offerType
}) => {
  const contractNumbersAutoGeneration = libs.contractNumbersAutoGenerationIsAllowed({ user, duplicata, config, amendment });

  useEffect(() => {
    getDocuments();
  }, [getDocuments]);

  const onBack = () => {
    goPreviousStep();
    resetFetching();
  };

  const onNext = () => {
    if (contractNumbersAutoGeneration) {
      generateContractNumbers(offerType);
    } else {
      resetFetching();
      goNextStep();
    }
  };

  const onPutUnhold = () => {
    putUnhold();
  };

  const onRetry = () => {
    resetFetching();
    getDocuments();
  };

  const isMedicalCheckRequired = () => !duplicata && !propositionStatusIsUnhold && medicalCheck;

  const statusIs = v => R.propEq('status', v, fetching);

  useKeyPressEnter(statusIs('conversion_failed') ? onRetry : onNext, !statusIs('converting_documents'));

  return {
    onBack,
    onNext,
    onPutUnhold,
    onRetry,
    isMedicalCheckRequired,
    statusIs,
    contractNumbersAutoGeneration
  };
};

const DocumentsStep = ({
  ccn,
  documents,
  fetching,
  propositionStatusIsUnhold,
  isAmendment,
  staff,
  getDocuments,
  goPreviousStep,
  resetFetching,
  goNextStep,
  putUnhold,
  medicalCheck,
  duplicata,
  user,
  generateContractNumbers,
  config,
  generateContractNumbersError,
  generateContractNumbersLoading,
  amendment,
  offerType
}) => {
  const {
    onBack,
    onNext,
    onPutUnhold,
    onRetry,
    isMedicalCheckRequired,
    statusIs,
    contractNumbersAutoGeneration
  } = useDocuments({
    getDocuments,
    goPreviousStep,
    resetFetching,
    goNextStep,
    putUnhold,
    medicalCheck,
    duplicata,
    propositionStatusIsUnhold,
    fetching,
    user,
    generateContractNumbers,
    config,
    amendment,
    offerType
  });

  if (R.isNil(fetching.status)) {
    return null;
  }

  return <>
    <FieldTitle
      text="Les documents contractuels"
      onBack={propositionStatusIsUnhold ? undefined : onBack}
    />

    {statusIs('converting_documents') && (
      <div>{`Génération des documents${S('.').repeat(fetching.count)}`}</div>
    )}

    {statusIs('documents_converted') && (
      <React.Fragment>
        <Documents
          value={documents}
          isAmendment={isAmendment}
          ccn={ccn}
          staff={staff}
          offerType={offerType}
        />

        {propositionStatusIsUnhold && (
          <div style={{ marginTop: '28px', color: '#444', fontSize: '12px' }}>
            {'Les documents téléchargés ne sont pas présentés ici.'}
            <br/><br/>
            {'Ils seront ajoutés pour la signature.'}
          </div>
        )}

        {isMedicalCheckRequired() && <div style={{ marginTop: '30px', fontWeight: 'bold' }}>
          Rappel : un contrôle médical est nécessaire.
        </div>}

        {!generateContractNumbersError && !generateContractNumbersLoading && (
          <div className="documents-step-actions">
            <BtnNext
              disabled={isMedicalCheckRequired()}
              onClick={onNext}
              title={contractNumbersAutoGeneration ? 'Confirmer la proposition' : 'Génération des contrats'}
            />
            {!propositionStatusIsUnhold && <BtnNext onClick={onPutUnhold} title={'Sauvegarder la proposition'}/>}
          </div>
        )}

        {generateContractNumbersLoading && <Loading/>}

        {generateContractNumbersError && <span style={{ color: 'red' }}>Entreprise inconnue dans ARPEJ, veuillez la créer avant de reprendre la proposition.</span>}

        {process.env.NODE_ENV === 'development' && <Button
          onClick={onRetry}
          style={{ marginTop: '20px' }}
        >
          [DEV] Regénérer les documents
        </Button>}
      </React.Fragment>
    )}

    {statusIs('conversion_failed') && (
      <div>
        {'Un problème est survenu pendant la conversion des documents, veuillez réessayer.'}
        <Button onClick={onRetry} style={{ marginTop: '10px' }}>Réessayer</Button>
      </div>
    )}
  </>;
};

export default withConfig(withUser(connect(
  ({ proposition }) => {
    const currentOfferType = R.pathOr('PREVOYANCE', ['data', 'currentOfferType'])(proposition);

    return {
      documents: R.prop('documents', proposition),
      fetching: R.prop('fetching', proposition),
      propositionStatusIsUnhold: R.pathEq(['data', 'status'], 'unhold', proposition),
      medicalCheck: R.path(['data', 'medicalCheck'], proposition),
      isAmendment: R.pathOr(false, ['data', 'amendment', 'isAmendment'])(proposition),
      ccn: R.path(['data', 'ccn'], proposition),
      staff: currentOfferType === 'SANTE' ? R.path(['data', 'health', 'staff'], proposition) : R.path(['data', 'staff'], proposition),
      duplicata: R.pathOr(false, ['data', 'duplicata'], proposition),
      generateContractNumbersError: R.propOr(false, 'generateContractNumbersError')(proposition),
      generateContractNumbersLoading: R.propOr(false, 'generateContractNumbersLoading')(proposition),
      amendment: currentOfferType === 'SANTE' ? R.pathOr({}, ['data', 'health', 'amendment'])(proposition) : R.pathOr({}, ['data', 'amendment'])(proposition),
      offerType: R.pathOr('PREVOYANCE', ['data', 'currentOfferType'])(proposition)
    };
  },
  dispatch => ({
    resetFetching: () => dispatch(propositionFetchingReset()),
    getDocuments: () => dispatch(propositionDocumentsGet()),
    goPreviousStep: () => dispatch(propositionGoPreviousStep()),
    goNextStep: () => dispatch(propositionGoNextStep()),
    putUnhold: () => dispatch(propositionPutUnhold(false)),
    generateContractNumbers: offerType => dispatch(propositionGenerateContractNumbers(offerType))
  })
)(DocumentsStep)));
