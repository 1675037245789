import * as R from 'ramda';
import React, { useState } from 'react';
import { connect } from 'react-redux';

import {
  propositionSetCcn,
  propositionGoNextStep
} from '../../../reduxActions/proposition';

import { Button, Sentence } from '../../../uikit/UIKit';
import {
  SelectCcn
} from '../components/.';

import withForm from '@thecodeisgreen/withform';
import Modal from '../../../uikit/modal/Modal';
import useKeyPressEnter from '../../../hooks/useKeyPressEnter';
import withUser from '../../../withUser';

const useSelectCcnStep = ({
  form,
  amendment,
  ccn,
  goNextStep,
  setValue,
  isDuplicata
}) => {
  const [displayModal, setDisplayModal] = useState(true);
  const onNextIsDisabled = R.compose(R.isNil, R.prop('ccn'))(form.values());

  const isAmendment = R.propOr(false, 'isAmendment')(amendment);
  const amendmentDisabled = isAmendment && R.compose(
    R.isEmpty,
    R.path(['ccn', 'disableAmendments'])
  )(form.values());
  const duplicataDisabled = isDuplicata && R.pathEq(['ccn', 'offerTypes'], ['SANTE'], form.values());

  const ccnHasBothOffers = R.compose(
    R.ifElse(
      R.isNil,
      R.always(false),
      R.propSatisfies(
        R.both(
          R.includes('SANTE'),
          R.includes('PREVOYANCE')
        ),
        'offerTypes'
      )
    ),
    R.prop('ccn')
  )(form.values());

  const ccnHasOnlyHealth = R.compose(
    R.propEq('offerTypes', ['SANTE']),
    R.propOr({}, 'ccn')
  )(form.values());

  const onNext = () => {
    const selectedCcn = form.values().ccn;
    if (selectedCcn.id !== R.propOr(null, 'id', ccn)) setValue(selectedCcn);
    goNextStep();
  };

  const buttonDisabled = onNextIsDisabled || amendmentDisabled || duplicataDisabled;
  useKeyPressEnter(onNext, !buttonDisabled);

  return {
    ccnHasBothOffers,
    ccnHasOnlyHealth,
    displayModal,
    setDisplayModal,
    isAmendment,
    buttonDisabled,
    amendmentDisabled,
    duplicataDisabled,
    onNext
  };
};

const SelectCcnStep = ({
  form,
  ccn,
  goNextStep,
  amendment,
  setValue,
  userRoleIs,
  isDuplicata
}) => {
  const {
    ccnHasBothOffers,
    ccnHasOnlyHealth,
    displayModal,
    setDisplayModal,
    isAmendment,
    buttonDisabled,
    amendmentDisabled,
    duplicataDisabled,
    onNext,
  } = useSelectCcnStep({
    form,
    amendment,
    ccn,
    goNextStep,
    setValue,
    isDuplicata
  });

  return <>
    {!R.isNil(R.pathOr(null, ['ccn', 'alert'])(form.values())) && !R.isEmpty(form.values().ccn.alert) && displayModal && !userRoleIs('apporteur') &&
      <Modal
        isPopup={true}
        onClose={() => setDisplayModal(false)}
        style={{ height: '200px' }}
      >
        <div style={{ fontSize: '1.2em', fontWeight: 'bold', textAlign: 'center' }}>{form.values().ccn.alert}</div>
      </Modal>
    }

    <Sentence
      headline="Quelques questions pour personnaliser votre contrat..."
      text="Quelle offre souhaitez-vous souscrire ?"
    />

    {form.manageField('ccn', {
      defaultValue: ccn,
      isUpdated: () => setDisplayModal(true)
    })(<SelectCcn disabled={R.propOr(false, 'isAmendment')(amendment) && !R.isNil(amendment.formerContractId)}/>)}

    {amendmentDisabled && <div style={{ color: 'red', marginTop: '20px' }}>
      {'Cette CCN ne propose pas d\'avenant.'}
    </div>}

    {duplicataDisabled && <div style={{ color: 'red', marginTop: '20px' }}>
      {'Cette CCN ne propose pas le parcours duplicata.'}
    </div>}

    {ccnHasBothOffers && !isAmendment && !isDuplicata && <div style={{ color: 'limegreen', marginTop: '20px' }}>
      Cette CCN propose des offres prévoyance et santé.
    </div>}

    {ccnHasOnlyHealth && !isAmendment && !isDuplicata && <div style={{ color: 'limegreen', marginTop: '20px' }}>
      {'Cette CCN propose uniquement l\'offre Santé.'}
    </div>}

    <Button
      disabled={buttonDisabled}
      style={{ marginTop: '40px' }}
      onClick={onNext}
    >
      Je personnalise mon contrat
    </Button>
  </>;
};

export default connect(
  ({ proposition }) => {
    return {
      ccn: R.prop('ccn')(proposition.data),
      amendment: R.propOr({}, 'amendment')(proposition.data),
      isDuplicata: R.pathEq(['data', 'duplicata'], true, proposition)
    };
  },
  dispatch => ({
    setValue: value => dispatch(propositionSetCcn(value)),
    goNextStep: () => dispatch(propositionGoNextStep())
  })
)(withUser(withForm(SelectCcnStep)));
