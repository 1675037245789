import * as R from 'ramda';
import React from 'react';
import Loading from '../../uikit/misc/Loading';
import { graphql, QueryRenderer } from 'react-relay';
import environment from '../../graphql/Environment';
import MenuLayout from '../../layout/menu/Layout';
import PropositionInfoDisplay from '../propositionInfo/components/PropositionInfoDisplay';

import {
  Button,
  FieldTitle,
} from '../../uikit';
import Error from '../../components/Error';

const AmendmentQuery = graphql`
  query AmendmentQuery($contractNumber: String, $status: String) {
    propositionInfo(contractNumber: $contractNumber, status: $status) {
      id
      ccn {
        id
        shortTitle
        disableAmendments
        isPack
      }
      ccnVersion
      postCode
      contractNumbers
      background {
        hasFormerContract
        atmtt {
          isSelected
          count
        }
      }
      brokerage {
        isSelected
      }
      startDate
      dates {
        creation
        lastUpdate
      }
      propositionNumber
      siren
      siret
      siretInfo {
        siret
        siren
        name
        legalForm
        address {
          city
          postCode
          fields
        }
      }
      status
      medicalCheck
      declarativeAnnexe
      outstandingAnnexe
      outstandingCheck
      companyInformation {
        contactFirstname
        contactLastname
        contactEmail
        contactMobile
        contactRole
      }
      staff {
        NC {
          isSelected
          count
        }
        AM {
          isSelected
          count
        }
        C {
          isSelected
          count
        }
      }
      contractDescription {
        NC {
          bases {
            baseId
            type
            options {
              optionId
            }
          }
        }
        AM {
          bases {
            baseId
            type
            options {
              optionId
            }
          }
        }
        C {
          bases {
            baseId
            type
            options {
              optionId
            }
          }
        }
      }
      duplicata
      relatedContract {
        checked
        oldLegalForm
        contractNumbers
      }
      user {
        email
        role
        extra {
          userCode
          region
          managementCenter
          lastname
          firstname
          sales
        }
      }
      overPricedRate
      regionTitle
      managementCenterName
      basesAndOptionsNamesC
      basesAndOptionsNamesNC
      basesAndOptionsNamesAM
    }
  }
`;

const Amendment = ({
  contractNumber
}) => {
  return <MenuLayout>
    {!R.isNil(contractNumber) && !R.isEmpty(contractNumber) ?
      <QueryRenderer
        environment={environment}
        query={AmendmentQuery}
        variables={{
          contractNumber,
          status: 'confirmedInManagementSystem'
        }}
        render={({ error, props }) => {
          if (error) {
            return <Error/>;
          } else if (!props) return <Loading/>;

          const propositionInfo = R.pathOr(null, ['propositionInfo'])(props);

          if (R.isNil(propositionInfo.id) || R.pathEq(['ccn', 'isPack'], true)(propositionInfo)) {
            return <div>
              <div style={{ margin: '20px 0' }}>
                Nous n&#39;avons pas trouvé de contrat avec ce numéro.<br/>
              </div>
              <div style={{ width: '50%', display: 'inline-block' }}>
                <Button to="menu">Retour au menu</Button></div>
              <div style={{ width: '50%', display: 'inline-block', marginBottom: '5px' }}>
                <Button to={`proposition?amendment=true&contractNumber=${contractNumber}`}>Créer un avenant</Button>
              </div>
            </div>;
          } else {
            const isClassic = R.compose(
              R.reduce((b, [, obj]) => {
                const _isClassic = R.reduce((_b, base) => _b && R.propEq('type', 'classique')(base), true)(R.propOr([], 'bases', obj));
                return b && _isClassic;
              }, true),
              R.toPairs,
              R.propOr({}, 'contractDescription')
            )(propositionInfo);

            const disableAmendments = R.path(['ccn', 'disableAmendments'], propositionInfo);
            const amendmentDisabled = !R.isNil(disableAmendments) && (
              R.isEmpty(disableAmendments) ||
              R.includes(parseInt(R.prop('postCode', propositionInfo)), disableAmendments)
            );

            return <div>
              <FieldTitle
                text={`Un contrat a été trouvé avec le numéro ${contractNumber}`}
              />
              <Button
                disabled={isClassic || amendmentDisabled}
                to={`proposition?amendment=true&formerContractId=${propositionInfo.id}`} size="medium"
              >
                Créer un avenant pour ce contrat
              </Button>

              {isClassic && <div style={{ color: 'red' }}>Vous ne pouvez pas créer d’avenant, il est nécessaire de créer une nouvelle souscription.</div>}
              {amendmentDisabled && <div style={{ color: 'red' }}>{'Cette CCN ne propose pas d\'avenant.'}</div>}

              <PropositionInfoDisplay proposition={propositionInfo} amendment style={{ marginTop: '25px' }}/>
              <Button to="menu" size="small" style={{ margin: '25px auto 0 auto', width: '200px' }}>Retour au menu</Button>
            </div>;
          }
        }}
      />
      :
      <span>Vous devez entrer un numéro de contrat.</span>
    }
  </MenuLayout>;
};

export default Amendment;
