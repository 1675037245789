import * as R from 'ramda';
import Moment from 'moment';
import { libs } from '@fasstech/spid-front';

const formatStartDate = (date) => R.isNil(date) || R.isEmpty(date) ? '' : Moment(date).format('DD/MM/YYYY');
const formatDate = (date) => R.isNil(date) || R.isEmpty(date) ? '' : Moment(date).format('DD/MM/YYYY HH:mm:ss');
const getCollegesInformations = (proposition) => {
  const getCollege = (college) => R.pathOr(R.pathOr({}, ['health', 'staff', college], proposition), ['staff', college])(proposition);

  const staff = R.compose(
    R.mergeAll,
    R.values,
    R.pick(['staff', 'staffHealth']),
    R.modify('staff', R.defaultTo({})),
    R.assoc('staffHealth', R.pathOr({}, ['health', 'staff'])(proposition))
  )(proposition);

  const c = [];

  R.forEach((college) => {
    if (college[1].isSelected) {
      c.push(libs.collegeLabel(college[0], proposition.ccn, R.pathOr('PREVOYANCE', ['offerTypes', 0], proposition)));
    }
  })(R.toPairs(staff));

  return {
    string: c.join(', '),
    C_isSelected: getCollege('C').isSelected,
    NC_isSelected: getCollege('NC').isSelected,
    APP_isSelected: getCollege('APP').isSelected,
    AM_isSelected: getCollege('AM').isSelected,
    TOUS_isSelected: getCollege('TOUS').isSelected,
    C_count: getCollege('C').count,
    NC_count: getCollege('NC').count,
    APP_count: getCollege('APP').count,
    AM_count: getCollege('AM').count,
    TOUS_count: getCollege('TOUS').count
  };
};
const getStatus = (status) => {
  const s = [];
  s['unhold'] = 'En attente';
  s['active'] = 'Actif';
  s['sentToManagementSystem'] = 'Envoyé au système de mise en gestion';
  s['sentForSignature'] = 'Envoyé en signature';
  s['sentForSignatureDelayed'] = 'Signature DEM TEL 24h';
  s['confirmed'] = 'Signé';
  s['ready'] = 'Prêt';
  s['closed'] = 'Fermé';
  s['duplicate'] = 'Duplicata généré';
  s['confirmedInManagementSystem'] = 'Confirmé par le système de mise en gestion';
  s['confirmedInManagementSystem'] = 'Confirmé par le système de mise en gestion réouvert';
  s['rejectedByManagementSystem'] = 'Rejeté par le système de mise en gestion';
  return !R.isNil(s[status]) ? s[status] : status;
};
const yesNo = (value) => value ? 'OUI' : 'NON';
const getRole = role => role === 'groupe_adhesion' ? 'Groupe adhésion' : (role === 'reseau_commercial' ? 'Réseau commercial' : '');

export {
  formatDate,
  formatStartDate,
  getCollegesInformations,
  yesNo,
  getRole,
  getStatus
};
