import './Proposition.less';

import * as R from 'ramda';

import { connect } from 'react-redux';

import {
  propositionSetAmendment,
  propositionSetBackground,
  propositionSetBrokerage,
  propositionSetCurrentStep,
  propositionSetCcn,
  propositionSetCompanyInformation,
  propositionSetContractDescription,
  propositionSetContractNumbers,
  propositionSetStaff,
  propositionSetLocation,
  propositionSetStartDate,
  propositionSetCompanySiren,
  propositionSetCompanySiret,
  propositionSetMedicalCheck,
  propositionSetDeclarativeAnnexe,
  propositionSetOutstandingAnnexe,
  propositionSetPropositionId,
  propositionSetStatus,
  propositionSetUserRole,
  propositionSetDuplicata,
  propositionSetCcnVersion,
  propositionSetExtraEstablishments,
  propositionSetCompanySiretInfo,
  propositionSetOverPricedRate,
  propositionSetRelatedContract,
  propositionSetSendForSignatureStatus,
  propositionSetOriginContractId,
  propositionSetAdhesionDate,
  propositionSetHealth,
  propositionSetOfferTypes,
  propositionSetCurrentOfferType,
  propositionSetSignature,
  propositionResetSendForSignatureStatus,
  propositionSetAdditionalInformations,
  propositionSetStudyAtIndem
} from '../../reduxActions/proposition';

import React from 'react';
import { graphql, QueryRenderer } from 'react-relay';
import environment from '../../graphql/Environment';
import withUser from '../../withUser';
import { withRouter } from 'react-router-dom';

import MenuLayout from '../../layout/menu/Layout';

import {
  compose,
  withHandlers,
  lifecycle
} from 'recompose';

import {
  AboutYouStep,
  BackgroundStep,
  CompanyEstablishmentStep,
  ConfirmContractStep,
  ContractIsConfirmedStep,
  ContractDescriptionStep,
  ContractNumbersStep,
  DeclarativeAnnexeStep,
  DocumentsStep,
  DuplicataSentStep,
  EmployeesStep,
  ErrorStep,
  LocationAndDateStep,
  MedicalCheckStep,
  OutstandingCheckStep,
  PendingPropositionStep,
  PropositionIsClosedStep,
  PutUnholdDoneStep,
  RelatedContractStep,
  RelatedContractHealthStep,
  SelectCcnStep,
  SelectOffersStep
} from './Steps';
import SendForSignatureStatusMutation from '../../graphql/mutations/SendForSignatureStatusMutation';
import { formatters } from '@fasstech/spid-front';
import Error from '../../components/Error';
import userMustHaveCommercialCode from '../../lib/userMustHaveCommercialCode';

const enhance = compose(
  withUser,
  withRouter,
  connect(
    state => ({
      currentStep: state.proposition.currentStep,
      information: {
        contractDescription: state.contractDescription.value
      },
      staff: R.pathOr({}, ['proposition', 'data', 'staff'], state)
    }),
    dispatch => ({
      propositionSetAmendment: amendment => dispatch(propositionSetAmendment(amendment)),
      propositionSetCcn: ccn => dispatch(propositionSetCcn(ccn)),
      propositionSetBackground: background => dispatch(propositionSetBackground(background)),
      propositionSetBrokerage: brokerage => dispatch(propositionSetBrokerage(brokerage)),
      propositionSetStaff: staff => dispatch(propositionSetStaff(staff)),
      propositionSetLocation: location => dispatch(propositionSetLocation(location)),
      propositionSetStartDate: startDate => dispatch(propositionSetStartDate(startDate)),
      propositionSetAdhesionDate: adhesionDate => dispatch(propositionSetAdhesionDate(adhesionDate)),
      propositionSetCompanyInformation: companyInformation => dispatch(propositionSetCompanyInformation(companyInformation)),
      propositionSetCompanySiren: siren => dispatch(propositionSetCompanySiren(siren)),
      propositionSetCompanySiret: siret => dispatch(propositionSetCompanySiret(siret)),
      propositionSetContractDescription: contractDescription => dispatch(propositionSetContractDescription(contractDescription)),
      propositionSetContractNumbers: contractNumbers => dispatch(propositionSetContractNumbers(contractNumbers)),
      propositionSetPropositionId: propositionId => dispatch(propositionSetPropositionId(propositionId)),
      propositionSetCurrentStep: propositionId => dispatch(propositionSetCurrentStep(propositionId)),
      propositionSetMedicalCheck: medicalCheck => dispatch(propositionSetMedicalCheck(medicalCheck)),
      propositionSetDeclarativeAnnexe: declarativeAnnexe => dispatch(propositionSetDeclarativeAnnexe(declarativeAnnexe)),
      propositionSetOutstandingAnnexe: outstandingAnnexe => dispatch(propositionSetOutstandingAnnexe(outstandingAnnexe)),
      propositionSetStatus: status => dispatch(propositionSetStatus(status)),
      propositionSetUserRole: role => dispatch(propositionSetUserRole(role)),
      propositionSetDuplicata: duplicata => dispatch(propositionSetDuplicata(duplicata)),
      propositionSetCcnVersion: ccnVersion => dispatch(propositionSetCcnVersion(ccnVersion)),
      propositionSetExtraEstablishments: extraEstablishments => dispatch(propositionSetExtraEstablishments(extraEstablishments)),
      propositionSetCompanySiretInfo: siretInfo => dispatch(propositionSetCompanySiretInfo(siretInfo)),
      propositionSetOverPricedRate: overPricedRate => dispatch(propositionSetOverPricedRate(overPricedRate)),
      propositionSetRelatedContract: relatedContract => dispatch(propositionSetRelatedContract(relatedContract)),
      propositionSetSendForSignatureStatus: status => dispatch(propositionSetSendForSignatureStatus(status)),
      propositionSetOriginContractId: originContractId => dispatch(propositionSetOriginContractId(originContractId)),
      propositionSetHealth: health => dispatch(propositionSetHealth(health)),
      propositionSetOfferTypes: offerTypes => dispatch(propositionSetOfferTypes(offerTypes)),
      propositionSetSignature: signature => dispatch(propositionSetSignature(signature)),
      propositionSetCurrentOfferType: currentOfferType => dispatch(propositionSetCurrentOfferType(currentOfferType)),
      propositionResetSendForSignatureStatus: () => dispatch(propositionResetSendForSignatureStatus()),
      propositionSetAdditionalInformations: additionalInformations => dispatch(propositionSetAdditionalInformations(additionalInformations)),
      propositionSetStudyAtIndem: studyAtIndem => dispatch(propositionSetStudyAtIndem(studyAtIndem))
    })
  ),

  lifecycle({
    componentWillMount () {
      /**
       * le role de l'utlisateur (reseau_commercial, groupe_adhesion) permet de faire des variantes dans le
       * parcours en fonction du rôle
       */
      const userRole = this.props.user.role;
      this.props.propositionSetUserRole(userRole);
      this.props.propositionResetSendForSignatureStatus();

      const addCommercialCode = () => {
        // Pour les commerciaux MDE et Mdpro, il faut renseigner par défaut le code commercial de l'utilisateur
        if (userMustHaveCommercialCode(this.props.user)) {
          this.props.propositionSetBrokerage({ commercialCode: R.path(['props', 'user', 'extra', 'userCode'], this), isSelected: false });
        }
      };

      if (!R.isNil(this.props.proposition)) {

        let {
          id,
          originContractId,
          amendment,
          background,
          brokerage,
          ccn,
          companyInformation,
          contractDescription,
          contractNumbers,
          medicalCheck,
          declarativeAnnexe,
          outstandingAnnexe,
          postCode,
          siren,
          siret,
          siretInfo,
          staff,
          startDate,
          adhesionDate,
          status,
          duplicata,
          ccnVersion,
          extraEstablishments,
          relatedContract,
          statusHistory,
          health,
          offerTypes,
          signature,
          additionalInformations,
          studyAtIndem
        } = this.props.proposition;

        contractDescription = formatters.formatContractDescriptionToSelection(contractDescription);
        health = R.modify('contractDescription', formatters.formatContractDescriptionToSelection)(health);

        const isAmendment = R.pathEq(['amendment', 'isAmendment'], 'true')(this.props);

        if (isAmendment) {
          addCommercialCode();

          // Pour la CCN Optique en mode avenant, si le contrat d'origine n'a que 2 numéros de contrats (= Base+Paritarisme), il faut insérer un numéro de contrat vide (= option) en deuxième position
          if (!R.isNil(contractNumbers) && !R.isEmpty(contractNumbers) && R.length(contractNumbers) < 3 && R.propEq('id', '3084-1431', ccn)) {
            contractNumbers = R.insert(1, '', contractNumbers);
          }
        }

        if (R.path(['props', 'amendment', 'formerContractId'])(this) !== id) {
          this.props.propositionSetPropositionId(id);
          this.props.propositionSetStatus(status);
          this.props.propositionSetRelatedContract(relatedContract);
          this.props.propositionSetDuplicata(duplicata);
          this.props.propositionSetBrokerage(brokerage);
          this.props.propositionSetBackground(background);
          this.props.propositionSetDeclarativeAnnexe(declarativeAnnexe);
          this.props.propositionSetOutstandingAnnexe(outstandingAnnexe);
        }
        this.props.propositionSetCcn(ccn);
        this.props.propositionSetLocation(postCode);
        this.props.propositionSetStartDate(startDate);
        this.props.propositionSetAdhesionDate(adhesionDate);
        this.props.propositionSetCompanyInformation(companyInformation);
        this.props.propositionSetCompanySiren(siren);
        this.props.propositionSetCompanySiret(siret);
        this.props.propositionSetMedicalCheck(medicalCheck);
        this.props.propositionSetStudyAtIndem(studyAtIndem);
        this.props.propositionSetStaff(staff);
        this.props.propositionSetContractDescription(contractDescription);
        this.props.propositionSetContractNumbers(contractNumbers);
        this.props.propositionSetCcnVersion(ccnVersion);
        this.props.propositionSetExtraEstablishments(extraEstablishments);
        this.props.propositionSetCompanySiretInfo(siretInfo);
        this.props.propositionSetOriginContractId(originContractId);
        this.props.propositionSetHealth(health);
        this.props.propositionSetOfferTypes(offerTypes);
        this.props.propositionSetSignature(signature);
        this.props.propositionSetAdditionalInformations(additionalInformations);
        this.props.propositionSetAmendment(!isAmendment ? amendment : {
          isAmendment,
          formerContractId: R.path(['amendment', 'formerContractId'])(this.props),
          formerNumberOfContracts: R.length(contractNumbers),
          formerColleges: R.compose(
            R.reduce((acc, s) => {
              if (s[1].isSelected) acc.push(s[0]);
              return acc;
            }, []),
            R.toPairs,
            R.reject(R.isNil)
          )(staff),
          formerContractDescription: formatters.formatContractDescriptionToSelection(R.pathOr({}, ['proposition', 'contractDescription'])(this.props))
        });

        if (!R.isNil(offerTypes) && !R.isEmpty(offerTypes)) {
          this.props.propositionSetCurrentOfferType(offerTypes[0]);
        }

        if (!R.isNil(R.path(['props', 'amendment', 'isAmendment'])(this))){
          this.props.propositionSetCurrentStep('SELECT_CCN_STEP');
        } else if (status === 'unhold') {
          this.props.propositionSetCurrentStep('PENDING_PROPOSITION_STEP');
        } else if (status === 'sendingForSignature') {
          const token = R.compose(
            R.pathOr(null, ['data', 'token']),
            R.findLast(
              R.propEq('status', 'sendingForSignature')
            )
          )(statusHistory);

          const getStatus = (token) => {
            setTimeout(() => {
              SendForSignatureStatusMutation({ propositionId: id, token }, (ok, status) => {
                this.props.propositionSetSendForSignatureStatus(status);
                if (ok && !status.error && status.value !== 'email_sent_to_user' && status.value !== 'conversion_failed') {
                  getStatus(token);
                }
              });
            }, 500);
          };
          getStatus(token);
          this.props.propositionSetCurrentStep('CONTRACT_NUMBERS_STEP');
        } else if (status === 'sentForSignature' || status === 'sentForSignatureDelayed') {
          this.props.propositionSetCurrentStep('CONFIRM_CONTRACT_STEP');
        } else if (status === 'confirmed') {
          this.props.propositionSetCurrentStep('CONTRACT_IS_CONFIRMED_STEP');
        } else if (status === 'duplicate') {
          this.props.propositionSetCurrentStep('DUPLICATA_SENT_STEP');
        } else if (status === 'closed') {
          this.props.propositionSetCurrentStep('PROPOSITION_IS_CLOSED_STEP');
        } else if (!R.isNil(originContractId)) {
          this.props.propositionSetCurrentStep('COMPANY_SIRET_STEP');
        } else if (status === 'active' && this.props.action === 'close_and_duplicate') {
          this.props.propositionSetCurrentStep('LOCATION_AND_DATE_STEP');
        } else if (this.props.currentStep !== 'EMPLOYEES_STEP' && this.props.currentStep !== 'SELECT_OFFERS_STEP') {
          this.props.propositionSetCurrentStep('ABOUT_YOU_STEP');
        }
      } else {

        const duplicata = userRole !== 'reseau_commercial' && R.propEq('duplicata', 'true')(this.props);
        const relatedContract = R.propEq('relatedContract', 'true')(this.props);

        const amendment = R.pathEq(['amendment', 'isAmendment'], 'true')(this.props);
        const formerContractId = R.path(['amendment', 'formerContractId'])(this.props);
        const contractNumber = R.path(['amendment', 'contractNumber'])(this.props);

        if (duplicata) this.props.history.replace('/proposition?duplicata=true');
        else if (relatedContract) this.props.history.replace('/proposition?relatedContract=true');
        else if (amendment) this.props.history.replace(`/proposition?amendment=true&formerContractId=${formerContractId}`);

        this.props.propositionSetDuplicata(duplicata);
        this.props.propositionSetRelatedContract({ checked: relatedContract });
        this.props.propositionSetAmendment({ isAmendment: amendment, formerContractId } );
        this.props.propositionSetContractNumbers([contractNumber]);
        addCommercialCode();
      }
    }
  }),

  withHandlers({
    showWhenStepIs: ({ currentStep, data }) => step => component => {   // eslint-disable-line
      const splittedStep = currentStep.split('|');
      const error = splittedStep[0] === 'ERROR_STEP' ? { errorId: R.propOr('', '1')(splittedStep) } : {};

      if (splittedStep[0] !== step) return null;
      return React.createElement(
        component,
        {
          data,
          ...error
        }
      );
    }
  })
);

const PropositionManager = enhance(
  ({
    showWhenStepIs
  }) => {
    return (
      <React.Fragment>
        {showWhenStepIs('BACKGROUND_STEP')(BackgroundStep)}
        {showWhenStepIs('PENDING_PROPOSITION_STEP')(PendingPropositionStep)}
        {showWhenStepIs('SELECT_CCN_STEP')(SelectCcnStep)}
        {showWhenStepIs('DECLARATIVE_ANNEXE_STEP')(DeclarativeAnnexeStep)}
        {showWhenStepIs('LOCATION_AND_DATE_STEP')(LocationAndDateStep)}
        {showWhenStepIs('EMPLOYEES_STEP')(EmployeesStep)}
        {showWhenStepIs('MEDICAL_CHECK_STEP')(MedicalCheckStep)}
        {showWhenStepIs('CONTRACT_DESCRIPTION_STEP')(ContractDescriptionStep)}
        {showWhenStepIs('CONTRACT_NUMBERS_STEP')(ContractNumbersStep)}
        {showWhenStepIs('ERROR_STEP')(ErrorStep)}
        {showWhenStepIs('COMPANY_SIRET_STEP')(CompanyEstablishmentStep)}
        {showWhenStepIs('ABOUT_YOU_STEP')(AboutYouStep)}
        {showWhenStepIs('DOCUMENTS_STEP')(DocumentsStep)}
        {showWhenStepIs('PROPOSITION_IS_CLOSED_STEP')(PropositionIsClosedStep)}
        {showWhenStepIs('OUTSTANDING_CHECK_STEP')(OutstandingCheckStep)}
        {showWhenStepIs('PUT_UNHOLD_DONE_STEP')(PutUnholdDoneStep)}
        {showWhenStepIs('CONFIRM_CONTRACT_STEP')(ConfirmContractStep)}
        {showWhenStepIs('CONTRACT_IS_CONFIRMED_STEP')(ContractIsConfirmedStep)}
        {showWhenStepIs('DUPLICATA_SENT_STEP')(DuplicataSentStep)}
        {showWhenStepIs('RELATED_CONTRACT_STEP')(RelatedContractStep)}
        {showWhenStepIs('RELATED_CONTRACT_HEALTH_STEP')(RelatedContractHealthStep)}
        {showWhenStepIs('SELECT_OFFERS_STEP')(SelectOffersStep)}
      </React.Fragment>
    );
  }
);

const PropositionQuery = graphql`
query PropositionQuery($id: ID) {
  data {
    departments {
      code
      title
    }
  }
  proposition(id: $id) {
    id
    originContractId
    offerTypes
    ccnId
    ccnVersion
    ccn {
      id
      offerTypes
      title
      shortTitle
      PREVOYANCE {
        colleges
        mandatoryColleges
        bundledCollege
        linkedCollege {
          C
          NC
          AM
          APP
          TOUS
        }
        staffMustBeAboveZero
        atLeastOneStaffMustBeAboveZero
        collegesLabels {
          APP
          AM
          NC
          C
        }
        collegesOrder
        displayPopupForColleges {
          colleges
          message
        }
        maxNumberOfEmployees {
          NC
          C
          AM
          APP
        }
        maxNumberOfTotalEmployees
        mustHaveAtLeastOneOfIfBaseIsSelected {
          basesIds
          selectedBaseId
        }
        contractDescriptionStepMessage
        conflictingColleges {
          colleges
        }
        contractNumbersByCollege
        unavailableBefore
        unavailableAfter {
          date
          message
        }
      }
      SANTE {
        colleges
        mandatoryColleges
        bundledCollege
        linkedCollege {
          C
          NC
          AM
          TOUS
          APP
        }
        meltedColleges {
          colleges
          targetCollege
        }
        conflictingColleges {
          colleges
        }
        contractNumbersByCollege
        staffMustBeAboveZero
        atLeastOneStaffMustBeAboveZero
        collegesLabels {
          AM
          NC
          C
          APP
        }
        collegesOrder
        displayPopupForColleges {
          colleges
          message
        }
        maxNumberOfEmployees {
          NC
          C
          AM
          APP
        }
        maxNumberOfTotalEmployees
        mustHaveAtLeastOneOfIfBaseIsSelected {
          basesIds
          selectedBaseId
        }
        garantiesRdgUrl
        cotisationsRdgUrl
        contractDescriptionStepMessage
        unavailableBefore
        unavailableAfter {
          date
          message
        }
        insurer
      }
      customerDetailsOptional
      idcc
      codeBrochure
      visionServiceUrl
      naf
      hasParitarism
      unavailableBefore
      isPack
      withClassicNumberOfContracts
      forbiddenDepartments
      amendmentsSettings {
        canUnselectOrigin
        collegesMustHaveBase
        collegesMustHaveZeroEmployees
      }
      relatedContractsSettings {
        contractNumbersCheckDisabled
      }
      brokerageSettings {
        bundledCollege
        unavailableBefore
      }
      brokerageOnly
      tariffStructures
    }
    contractNumbers
    background {
      hasFormerContract
      atmtt {
        isSelected
        count
      }
    }
    brokerage {
      isSelected
      commercialCode
      commercialCodeUserEmail
      provider
    }
    postCode
    startDate
    adhesionDate
    propositionNumber
    siren
    siret
    siretInfo {
      siret
      siren
      name
      isHeadOffice
      nic
      naf
      ape
      legalForm
      manual
      address {
        city
        postCode
        fields
      }
      codeApplication
      identifiantDansSilo
    }
    extraEstablishments {
      name
      siren
      siret
      isHeadOffice
      nic
      naf
      ape
      legalForm
      manual
      address {
        city
        postCode
        fields
      }
      identifiantDansSilo
      codeApplication
    }
    status
    statusHistory {
      status
      when
      data {
        token
      }
    }
    medicalCheck
    declarativeAnnexe
    outstandingAnnexe
    studyAtIndem
    companyInformation {
      contactFirstname
      contactLastname
      contactEmail
      contactMobile
      contactRole
      contactRoleCode
      accountantEmail
      sendToAccountant
      commercialName
      fixedAPE
      fixedAddress {
        isChecked
        postCode
        city
        fields
      }
      escAdmin {
        type
        civility
        lastName
        firstName
        role
        roleCode
        email
        phoneNumber
        birthDate
        birthCountry
        birthCity
        unicityCheck {
          unique
        }
      }
    }
    staff {
      NC {
        isSelected
        count
      }
      APP {
        isSelected
        count
      }
      AM {
        isSelected
        count
      }
      C {
        isSelected
        count
      }
      TOUS {
        isSelected
        count
      }
    }
    contractDescription {
      NC {
        bases {
          baseId
          options {
            optionId
          }
        }
      }
      APP {
        bases {
          baseId
          options {
            optionId
          }
        }
      }
      AM {
        bases {
          baseId
          options {
            optionId
          }
        }
      }
      C {
        bases {
          baseId
          options {
            optionId
          }
        }
      }
      TOUS {
        bases {
          baseId
          options {
            optionId
          }
        }
      }
    }
    duplicata
    overPricedRate
    relatedContract {
      checked
      oldLegalForm
      contractNumbers
      closePreviousContract
      oldSiren
      oldNic
      startDate
      type
      hasKBIS
    }
    amendment {
      isAmendment
      formerContractId
      formerNumberOfContracts
      formerColleges
      formerContractDescription {
        NC {
          bases {
            baseId
            options {
              optionId
            }
          }
        }
        APP {
          bases {
            baseId
            options {
              optionId
            }
          }
        }
        AM {
          bases {
            baseId
            options {
              optionId
            }
          }
        }
        C {
          bases {
            baseId
            options {
              optionId
            }
          }
        }
        TOUS {
          bases {
            baseId
            options {
              optionId
            }
          }
        }
      }
    }
    turnovers {
      college
      contractNumberIndex
      value
    }
    health {
      ccnVersion
      contractDescription {
        NC {
          bases {
            baseId
            options {
              optionId
            }
          }
        }
        APP {
          bases {
            baseId
            options {
              optionId
            }
          }
        }
        AM {
          bases {
            baseId
            options {
              optionId
            }
          }
        }
        C {
          bases {
            baseId
            options {
              optionId
            }
          }
        }
        TOUS {
          bases {
            baseId
            options {
              optionId
            }
          }
        }
      }
      contractNumbers
      numberOfContracts
      medicalCheck
      insurer
      tariffStructure
      regime
      staff {
        NC {
          isSelected
          count
        }
        APP {
          isSelected
          count
        }
        AM {
          isSelected
          count
        }
        C {
          isSelected
          count
        }
        TOUS {
          isSelected
          count
        }
      }
      turnovers {
        college
        contractNumberIndex
        value
      }
      amendment {
        isAmendment
        formerContractId
        formerNumberOfContracts
        formerColleges
        formerContractDescription {
          NC {
            bases {
              baseId
              options {
                optionId
              }
            }
          }
          APP {
            bases {
              baseId
              options {
                optionId
              }
            }
          }
          AM {
            bases {
              baseId
              options {
                optionId
              }
            }
          }
          C {
            bases {
              baseId
              options {
                optionId
              }
            }
          }
          TOUS {
            bases {
              baseId
              options {
                optionId
              }
            }
          }
        }
      }
    }
    signature {
      type
    }
    additionalInformations {
      PREVOYANCE {
        hasLessThan20Employees
      }
    }
  }
}
`;

const Proposition = ({
  propositionId,
  duplicata,
  relatedContract,
  amendment,
  action,
}) => {
  return <MenuLayout>
    <QueryRenderer
      environment={environment}
      query={PropositionQuery}
      variables={{
        id: R.isNil(R.prop('formerContractId')(amendment)) ? propositionId : R.prop('formerContractId')(amendment),
      }}
      render={({ error, props }) => {
        if (error) {
          return <Error/>;
        } else if (!props) {
          return null;
        }

        const { data, proposition } = props;
        return <PropositionManager
          data={data}
          proposition={proposition}
          duplicata={duplicata}
          relatedContract={relatedContract}
          amendment={amendment}
          action={action}
        />;
      }}
    />
  </MenuLayout>;
};

export default Proposition;
