import * as R from 'ramda';
import React from 'react';
import PropTypes from 'prop-types';
import {
  Switch,
  Redirect,
  Route,
  BrowserRouter as Router,
} from 'react-router-dom';
import qs from 'querystring';
import S from 'string';
import { Provider } from 'react-redux';
import Moment from 'moment';
Moment.locale('fr');
import Account from './pages/account/Account';
import Menu from './pages/menu/Menu';
import SystemError from './pages/systemError/SystemError';
import Proposition from './pages/proposition/Proposition';
import SelectProposition from './pages/proposition/SelectProposition';
import PropositionInfo from './pages/propositionInfo/PropositionInfo';
import Amendment from './pages/proposition/Amendment';
import SignatureError from './pages/proposition/components/SignatureError';
import User from './User';
import { UserContext } from './withUser';
import AdminLazyLoading from './pages/admin/AdminLazyLoading';
import Ccns from './pages/ccns/Ccns';
import QConfig from './graphql/queries/QConfig';
import { ConfigContext } from './withConfig';
import AuthError from './pages/account/AuthError';
import QEnv from './graphql/queries/QEnv';
import { SSOContext } from './withSSO';
import DevTags from './layout/DevTags';

const userIs = (user, role) => R.propEq('role', role)(user);
const query = queryString => qs.parse(S(queryString).chompLeft('?').s);

const Routes = ({ store }) => {
  return <User refreshInterval={120000}>
    {(user) => {
      const userIsSignedIn = !R.isNil(R.propOr(null, 'username', user));
      const signUpNeeded = R.pathOr(false, ['extra', 'signUpNeeded'], user);
      const userStatusIsPending = R.propEq('status', 'pending')(user);

      return <UserContext.Provider value={user}>
        <QEnv envVar="DISABLE_SSO">
          {({ DISABLE_SSO }) => {
            const SSO_ENABLED = DISABLE_SSO !== 'on';

            return <SSOContext.Provider value={{ SSO_ENABLED }}>
              <QConfig>
                {config => <ConfigContext.Provider value={config}>
                  <Provider store={store}>
                    <DevTags/>
                    <Router>
                      <div className="body-wrapper">
                        <Switch>
                          <Route exact path="/signatureError" render={({ location }) => <SignatureError error={R.propOr('', 'error')(query(location.search))}/> }/>
                          <Route path="/account" component={Account}/>
                          {!userIsSignedIn && SSO_ENABLED && <Redirect to="/account/auth"/>}
                          {!userIsSignedIn && !SSO_ENABLED && <Redirect to="/account/signin"/>}
                          {userIsSignedIn && SSO_ENABLED && signUpNeeded && <Redirect to="/account/signup"/>}
                          {!userIsSignedIn && SSO_ENABLED && <Route exact path="/auth/error" component={AuthError}/> }
                          {userIsSignedIn && userStatusIsPending && <Redirect to="/account/signeduppending"/>}

                          {R.propOr(false, 'resetPasswordRequired', user) && <Redirect to="/account/newpasswordrequired"/>}

                          {R.pathOr(false, ['extra', 'isManager'])(user) && <Route path="/admin" render={() => <AdminLazyLoading isCommercialNetwork={userIs(user, 'reseau_commercial')}/>}/>}
                          <Route
                            exact path="/menu" render={() => <Menu isCommercialNetwork={userIs(user, 'reseau_commercial')} user={user}/> }
                          />

                          <Route
                            exact path="/selectionnerproposition" render={({ location }) => {
                              const { siren } = query(location.search);
                              return <SelectProposition siren={siren}/>;
                            }}
                          />

                          <Route
                            exact path="/proposition" render={({ location }) => {
                              const {
                                propositionId,
                                duplicata,
                                relatedContract,
                                amendment,
                                formerContractId,
                                contractNumber,
                                action
                              } = query(location.search);

                              return <Proposition
                                action={action}
                                propositionId={propositionId}
                                duplicata={duplicata}
                                relatedContract={relatedContract}
                                amendment={{ isAmendment: amendment, formerContractId: formerContractId === 'undefined' ? null : formerContractId, contractNumber }}
                              />;
                            }}
                          />

                          <Route
                            exact path="/propositionInfo" render={({ location }) =>
                              <PropositionInfo propositionId={R.propOr('', 'propositionId')(query(location.search))}/>
                            }
                          />

                          <Route
                            exact path="/amendment" render={({ location }) =>
                              <Amendment contractNumber={R.propOr('', 'contractNumber')(query(location.search))}/>
                            }
                          />

                          <Route exact path="/recommandations_ccns" component={Ccns}/>

                          <Route
                            exact path="/erreur" render={({ location }) => {
                              const { id } = query(location.search);
                              return <SystemError errorId={id}/>;
                            }}
                          />

                          <Redirect to="/menu"/>
                        </Switch>
                      </div>
                    </Router>
                  </Provider>
                </ConfigContext.Provider>}
              </QConfig>
            </SSOContext.Provider>;
          }}
        </QEnv>
      </UserContext.Provider>;
    }}
  </User>;
};

Routes.propTypes = {
  store: PropTypes.object.isRequired
};

export default Routes;
