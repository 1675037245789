import * as R from 'ramda';
import React, { Suspense } from 'react';
import { BtnNext, ContractDescriptionManager } from './index';
const Info = React.lazy(() => import(/* webpackChunkName: "Info" */ './Info'));
import { Button, FieldTitle, Modal } from '../../../uikit/UIKit';
import useKeyPressEnter from '../../../hooks/useKeyPressEnter';
import withUser from '../../../withUser';
import { hooks } from '@fasstech/spid-front';
import VisionService from './contractDescription/VisionService';

const ContractDescriptionPrevoyance = ({
  amendment,
  bases,
  ccn,
  ccnId,
  colleges,
  duplicata,
  formerContractDescription,
  goPreviousStep,
  goPreviousStepAmendment,
  hasValue,
  isUnknownAmendment,
  onNext,
  onToggleModal,
  onUpdate,
  onUpdateAmendment,
  propositionId,
  showModal,
  startDate,
  stepAmendment,
  user,
  value,
  visionServiceUrl,
  mustHaveAtLeastOneOfIsValid,
  loading
}) => {
  const {
    isClassic,
    messages,
    buttonDisabled,
    buttonOnNext,
    displayIsOriginEquals,
    displayCollegesDontHaveBase
  } = hooks.useContractDescription({
    bases,
    value,
    startDate,
    duplicata,
    amendment,
    isUnknownAmendment,
    stepAmendment,
    hasValue,
    colleges,
    onNext,
    onUpdateAmendment,
    ccn,
    mustHaveAtLeastOneOfIsValid
  });

  useKeyPressEnter(buttonOnNext, !buttonDisabled);

  const contractDescriptionStepMessage = R.path(['PREVOYANCE', 'contractDescriptionStepMessage'], ccn);

  return <div>
    <FieldTitle
      text={!stepAmendment ? 'Les contrats possibles par collège' : 'Sélection du contrat d\'origine'}
      onBack={!isUnknownAmendment || stepAmendment ? goPreviousStep : goPreviousStepAmendment}
    />

    <div className="contract-description-actions">
      <div className="contract-description-actions-left">
        {
          R.includes('FEATURE_COMMERCIAL_INFORMATION', user.grants) && (
            <Button size="medium" onClick={onToggleModal}>{'Détail des garanties'}</Button>
          )
        }
      </div>

      <VisionService visionServiceUrl={visionServiceUrl}/>
    </div>
    <div className="contract-description-step-colleges">
      {
        R.includes('FEATURE_COMMERCIAL_INFORMATION', user.grants) && showModal && (
          <Modal
            onClose={onToggleModal}
          >
            <Suspense fallback={<div>Loading...</div>}>
              <Info
                propositionId={propositionId}
                ccnId={ccnId}
              />
            </Suspense>
          </Modal>
        )
      }
      <ContractDescriptionManager
        bases={bases}
        colleges={colleges}
        defaultValue={stepAmendment ? formerContractDescription : value}
        onChange={onUpdate}
        amendment={amendment}
        stepAmendment={stepAmendment}
        ccn={ccn}
      />
    </div>
    <div>
      {displayIsOriginEquals && <div style={{ color: 'red' }}>L’avenant est identique au contrat d’origine, la souscription de l’avenant est impossible.</div>}
      {displayCollegesDontHaveBase && <div style={{ color: 'red' }}>L’avenant est impossible.</div>}
      {isClassic && !stepAmendment && <div style={{ color: 'red' }}>Vous ne pouvez pas créer d’avenant, il est nécessaire de créer une nouvelle souscription.</div>}
      {!R.isEmpty(messages) && <div style={{ color: 'red' }}>
        {R.map(message => <span key={message}>{message}<br/></span>)(messages)}
      </div>}
      {!R.isNil(contractDescriptionStepMessage) && <div style={{ color: 'red' }}>{contractDescriptionStepMessage}</div>}

      <BtnNext
        disabled={buttonDisabled}
        onClick={buttonOnNext}
        loading={loading}
        title={stepAmendment ? 'Valider le contrat d\'origine' : 'Valider'}
      />
    </div>
  </div>;
};

export default withUser(ContractDescriptionPrevoyance);
